import { Injectable, inject } from '@angular/core';
import { exhaustMap, filter, first, map, mergeMap, switchMap, tap } from 'rxjs';
import { createEffect, ofType } from '@ngrx/effects';

import { BaseEffect } from '../base.effect';
import { TablesStore } from './tables.reducer';
import { TableRelationsService } from '@Services/table-relations.service';
import { uniqBy } from 'lodash-es';

@Injectable()
export class TablesEffect extends BaseEffect {
  protected tableRelationsService = inject(TableRelationsService);

  groupsGetAll = createEffect(() =>
    this.actions$.pipe(
      ofType(TablesStore.actions.groups.getAll),
      mergeMap(() => this.store.select(TablesStore.selectors.groups).pipe(first())),
      filter((cachedGroups) => !cachedGroups || !cachedGroups.length),
      exhaustMap((action) => this.tableRelationsService.getAllTableGroups()),
      map((r) => TablesStore.actions.groups.getAllSuccess({ result: r })),
    ),
  );

  tablesGetByGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(TablesStore.actions.tables.getByGroup),
      tap((q) => console.log('Log get tables by group', q)),
      mergeMap((action) =>
        this.store.select(TablesStore.selectors.tablesByGroupMap).pipe(
          first(),
          map((r) => ({ action, map: r })),
        ),
      ),
      filter((item) => !item.map[item.action.group] && !!item.action.group),
      mergeMap(({ action }) =>
        this.tableRelationsService
          .getTablesByTableGroup(action.group)
          .pipe(map((r) => ({ tables: r, ...action }))),
      ),
      map((r) => TablesStore.actions.tables.getByGroupSuccess({ result: r })),
    ),
  );

  getAllTables = createEffect(() =>
    this.actions$.pipe(
      ofType(TablesStore.actions.allTables.getAll),
      tap((q) => console.log('Log get all tables', q)),
      mergeMap((action) =>
        this.store.select(TablesStore.selectors.gotAllTables).pipe(
          first(),
          map((r) => ({ action, gotAllTables: r })),
        ),
      ),
      filter((item) => !item.gotAllTables),
      exhaustMap(({ action }) =>
        this.tableRelationsService
          .getAllTableRelations()
          .pipe(
            map((r) =>
              uniqBy(
                r
                  .map((x) => ({ ...x, _primaryTableUpper: x.primaryTable?.toUpperCase() }))
                  .sort3('primaryTable'),
                (i) => i.primaryTable,
              ),
            ),
          ),
      ),
      map((r) => TablesStore.actions.allTables.getAllSuccess({ result: r })),
    ),
  );

  columnsGetByTable = createEffect(() =>
    this.actions$.pipe(
      ofType(TablesStore.actions.columns.getByTable),
      tap((q) => console.log('Log get columns by table', q)),
      mergeMap((action) =>
        this.store.select(TablesStore.selectors.columnsBySchemaMap).pipe(
          first(),
          map((r) => ({ action, map: r })),
        ),
      ),
      filter((item) => !item.map[item.action.schema]?.[item.action.tableName]),
      mergeMap(({ action }) =>
        this.tableRelationsService.getFieldNames(action.tableName, action.schema).pipe(
          map((r) => ({
            ...action,
            fields: r?.map((x) => ({
              ...x,
              _formattedName: this.uS.fieldToLabelMap(x?.columnName?.toLowerCase().replaceAll('_', ' ')),
            })),
          })),
        ),
      ),
      map((r) => TablesStore.actions.columns.getByTableSuccess({ result: r })),
    ),
  );
}
