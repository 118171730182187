import { AuthEffect } from './auth/auth.effect';
import { CodeEffect } from './code/code.effect';
import { LabelsEffect } from './labels/labels.effect';
import { TablesEffect } from './tables/tables.effect';
import { TranslationGlossaryEffect } from './translation/translation.effect';
import { UserStateEffect } from './user-state/user-state.effect';
import { UserMenuEffect } from './usermenu/usermenu.effect';

export const effects = [
  // CodeEffect,
  AuthEffect,
  LabelsEffect,
  UserMenuEffect,
  TranslationGlossaryEffect,
  UserStateEffect,
  TablesEffect,
];
