import { Injectable, inject } from '@angular/core';
import { filter, first, map, switchMap } from 'rxjs';
import { createEffect, ofType } from '@ngrx/effects';

import { BaseEffect } from '../base.effect';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { UserStateStore } from './user-state.reducer';
import { StorageService } from '@Services/storage.service';
import { AuthStore } from '../auth/auth.reducer';

@Injectable()
export class UserStateEffect extends BaseEffect {
  protected service = inject(AuthenticationService);
  protected sS = inject(StorageService);

  //#region USER GROUP
  setUserGroupFromLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.fetchUserStateDetails),
      first((x) => !!x.userProfile),
      switchMap((action) => this.service.getUserGroupFromLocal()),
      filter((x) => !!x),
      map((r) => UserStateStore.actions.userGroup.setItem({ item: r })),
    ),
  );

  getUserGroupFromOnline = createEffect(() =>
    this.actions$.pipe(
      // ofType(UserStateStore.actions.userGroup.getFromOnline),
      ofType(AuthStore.actions.getUserOSucces),
      map((action) => action.userProfile.users.group),
      filter((code) => !!code),
      switchMap((action) => this.service.getUserGroupFromOnline(action)),
      map((r) => UserStateStore.actions.userGroup.getFromOnlineSuccess({ item: r })),
    ),
  );

  setUserGroupFromOnlineSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStateStore.actions.userGroup.getFromOnlineSuccess),
      map((r) => UserStateStore.actions.userGroup.setItem(r)),
    ),
  );

  saveUserGroupToLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStateStore.actions.userGroup.getFromOnlineSuccess),
      switchMap((action) => this.service.saveUserGroupToLocal(action.item)),
      map((r) => UserStateStore.actions.userGroup.end()),
    ),
  );
  //#endregion

  //#region COMPANY CONFIG
  setCompanyConfigFromLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.fetchUserStateDetails),
      first((x) => !!x.userProfile),
      switchMap((action) => this.service.getUserCompanyConfigFromLocal()),
      // filter((x) => !!x),
      map((r) => UserStateStore.actions.companyConfig.setItem({ item: r })),
    ),
  );

  triggerGetCompanyConfigFromOnline = createEffect(() =>
    this.actions$.pipe(
      // ofType(UserStateStore.actions.companyConfig.getFromOnline),
      ofType(AuthStore.actions.getUserOSucces),
      map((action) => action.userProfile.users.primaryCompany),
      filter((code) => !!code),
      map((code) => UserStateStore.actions.companyConfig.getFromOnline({ companyCode: code })),
    ),
  );

  getCompanyConfigFromOnline = createEffect(() =>
    this.actions$.pipe(
      // ofType(UserStateStore.actions.companyConfig.getFromOnline),
      ofType(UserStateStore.actions.companyConfig.getFromOnline),
      switchMap(({ companyCode: code }) => this.service.getUserCompanyConfigFromOnline(code)),
      map((r) => UserStateStore.actions.companyConfig.getFromOnlineSuccess({ item: r })),
    ),
  );

  setCompanyConfigFromOnlineSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStateStore.actions.companyConfig.getFromOnlineSuccess),
      map((r) => UserStateStore.actions.companyConfig.setItem(r)),
    ),
  );

  saveCompanyConfigToLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStateStore.actions.companyConfig.getFromOnlineSuccess),
      switchMap((action) => this.service.saveUserCompanyConfigToLocal(action.item)),
      map((r) => UserStateStore.actions.companyConfig.end()),
    ),
  );
  //#endregion

  //#region COMPANY
  // setCompanyFromLocal = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthStore.actions.fetchUserStateDetails),
  //     first((x) => !!x.userProfile),
  //     switchMap((action) => this.service.getUserCompanyFromLocal()),
  //     // filter((x) => !!x),
  //     map((r) => UserStateStore.actions.company.setItem({ item: r })),
  //   ),
  // );

  // triggerGetCompanyFromOnline = createEffect(() =>
  //   this.actions$.pipe(
  //     // ofType(UserStateStore.actions.company.getFromOnline),
  //     ofType(AuthStore.actions.getUserOSucces),
  //     map((action) => action.userProfile.users.primaryCompany),
  //     filter((code) => !!code),
  //     map((code) => UserStateStore.actions.company.getFromOnline({ companyCode: code })),
  //   ),
  // );

  // getCompanyFromOnline = createEffect(() =>
  //   this.actions$.pipe(
  //     // ofType(UserStateStore.actions.company.getFromOnline),
  //     ofType(UserStateStore.actions.company.getFromOnline),
  //     switchMap(({ companyCode: code }) => this.service.getUserCompanyFromOnline(code)),
  //     map((r) => UserStateStore.actions.company.getFromOnlineSuccess({ item: r })),
  //   ),
  // );

  // setCompanyFromOnlineSuccess = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserStateStore.actions.company.getFromOnlineSuccess),
  //     map((r) => UserStateStore.actions.company.setItem(r)),
  //   ),
  // );

  // saveCompanyToLocal = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserStateStore.actions.company.getFromOnlineSuccess),
  //     switchMap((action) => this.service.saveUserCompanyToLocal(action.item)),
  //     map((r) => UserStateStore.actions.company.end()),
  //   ),
  // );
  //#endregion
}
