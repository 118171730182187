import { Component } from '@angular/core';
import { SharedModule } from '../../../../shared.module';

@Component({
  selector: 'user-help-task',
  imports: [SharedModule],
  templateUrl: './user-help-task.component.html',
  styleUrl: './user-help-task.component.scss',
})
export class UserHelpTaskComponent { }
