import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ChatHelpDeskComponent } from '../../../evolutics-shared-lib/src/lib/Shared/components/page-icons/chat-help-desk/chat-help-desk.component';
import { LoaderComponent } from 'ets-fe-ng-sdk';
import { MatProgressBar } from '@angular/material/progress-bar';
import { SharedAppComponent } from 'projects/evolutics-shared-lib/src/lib/shared-app.component';
import { ToastNotificationsComponent } from '@serene-dev/toast-notifications';
import { UserHelperComponent } from '@Shared/components/page-icons/user-helper/user-helper.component';
import { EUserHelperID } from '@Shared/components/page-icons/user-helper/user-helper.model';
import { CMSService } from '@Reusables/reusable-pages/cms/cms.service';
import { ECMSContentRefCat, ECMSModuleType } from '@Reusables/reusable-pages/cms/cms.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    MatProgressBar,
    LoaderComponent,
    RouterOutlet,
    // ChatHelpDeskComponent,
    ToastNotificationsComponent,
    UserHelperComponent,
  ],
})
export class AppComponent extends SharedAppComponent {
  readonly cmsService = inject(CMSService);
  async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.userHelperService.updateConfig({
      hiddenModules: { [EUserHelperID.quote]: true, [EUserHelperID.notification]: false },
      applicationCode: 'JP',
      // searchHelpFunction: (query) =>
      //   this.cmsService.getPageRelatedResources({
      //     applicationCode: 'JP',
      //     moduleType: ECMSModuleType.help,
      //     refCat: ECMSContentRefCat.menuItem,
      //     refId: query.path,
      //   }),
    });
  }
}
