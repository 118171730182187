import { Injectable, inject } from '@angular/core';
import { map, switchMap } from 'rxjs';
import { createEffect, ofType } from '@ngrx/effects';

import { BaseEffect } from '../base.effect';
import { TranslationGlossaryStore } from './translation.reducer';
import { StorageService } from '@Services/storage.service';
import { TranslationGlossaryService } from 'projects/evolutics-client-ui/src/app/Life/Setup/translation/translation-extras/translation-glossary.service';

@Injectable()
export class TranslationGlossaryEffect extends BaseEffect {
  protected service = inject(TranslationGlossaryService);
  protected sS = inject(StorageService);
 

  getFromOnline = createEffect(() =>
    this.actions$.pipe(
      ofType(TranslationGlossaryStore.actions.getFromOnline),
      switchMap((action) => this.service.getGlossary()),
      map((r) => TranslationGlossaryStore.actions.getFromOnlineSuccess()),
    ),
  );
 
}
