import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SharedModule } from '../../../../shared.module';

@Component({
  selector: 'user-help-chat',
  imports: [SharedModule],
  templateUrl: './user-help-chat.component.html',
  styleUrl: './user-help-chat.component.scss',
})
export class UserHelpChatComponent { }
