import { Injectable, inject } from '@angular/core';
import { map, switchMap, tap } from 'rxjs';
import { createEffect, ofType } from '@ngrx/effects';

import { BaseEffect } from '../base.effect';
import { LabelsStore } from './labels.reducer';
import { StorageService } from '@Services/storage.service';
import { LabelsService } from '@Services/customization/labels.service';
import { AuthStore } from '../auth/auth.reducer';

@Injectable()
export class LabelsEffect extends BaseEffect {
  protected service = inject(LabelsService);
  protected sS = inject(StorageService);

  setFromLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.fetchUserStateDetails),
      switchMap(() => this.service.getFromLocal()),
      map((r) => LabelsStore.actions.setList({ labels: r })),
    ),
  );

  getFromOnline = createEffect(() =>
    this.actions$.pipe(
      ofType(LabelsStore.actions.getFromOnline),
      switchMap((action) => this.service.getAllLabels()),
      map((r) => LabelsStore.actions.getListFromOnlineSuccess({ labels: r })),
    ),
  );

  setListFromOnline = createEffect(() =>
    this.actions$.pipe(
      ofType(LabelsStore.actions.getListFromOnlineSuccess),
      map((r) => LabelsStore.actions.setList({ labels: r.labels })),
    ),
  );

  saveToLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(LabelsStore.actions.getListFromOnlineSuccess),
      switchMap((action) => this.service.saveToLocal(action.labels)),
      map((r) => LabelsStore.actions.saveToLocalSuccess()),
    ),
  );
}
