import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { Config } from '@configs/index.config';
import { AppService } from './app.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class WindowSecurityService {
  constructor(public uS: UtilityService) {}
  startTabCheck(singleTabUsage: boolean) {
    if (!singleTabUsage) return;
    try {
      const channel = new BroadcastChannel('tab');

      channel.postMessage('another-tab');
      // note that listener is added after posting the message

      channel.addEventListener('message', (msg) => {
        if (msg.data === 'another-tab') {
          debugger;
          // message received from 2nd tab
          channel.postMessage(`No you're not.`);
        }
        if (msg.data === `No you're not.`) {
          // debugger;
          environment.alreadyOpened = true;
          // this.uS.go(Config.alreadyOpenedRoute);
          // setTimeout(() => {

          //   this.uS.info(`An instance has already been opened and it will be closed`,0,null,null,true);
          // }, 2000);
          // alert(`An instance of this site is already running`);
        }
      });
    } catch (error) {
      console.error(error);
      this.useOtherSingleTabCheck();
    }
  }
  useOtherSingleTabCheck() {}
}

// export { ETSWindowSecurityService as WindowSecurityService } from 'ets-fe-ng-sdk';
