import { Injectable } from '@angular/core';
import { AppSaverService } from '@Services/cache/saver.service';
import { ModuleColourService } from '@Services/module-colour.service';
import { EModuleThemeCat, IModuleTheme } from './module-theme-setup.model';
import { Config } from 'projects/evolutics-client-ui/src/app/configs/index.config';
import { environment } from '@environments/environment';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModuleThemeSetupService extends AppSaverService {
  private moduleThemes: IModuleTheme[];
  constructor(public mCS: ModuleColourService) {
    super('moduleThemes', 'moduleTheme');
    // debugger;
    this.applyModuleTheme();
  }

  /**
   * Apply module themes setup on the web app
   * @param moduleThemes The module themes setup
   */
  applyModuleTheme() {
    this.getFromLocal();
    this.patchColours();
    setTimeout(() => {
      this.mCS.restFuncs
        .searchColourCodes({ queryObject: { refCat: EModuleThemeCat.app, refNo: environment.userName } })
        .subscribe((r) => {
          this.moduleThemes = r?.content;
          this.patchColours();
        });
    }, 1000);
  }

  saveModuleTheme(moduleThemes: IModuleTheme[]) {
    return this.mCS.restFuncs.saveColourCode({ body: moduleThemes }).pipe(
      tap(() => {
        this.moduleThemes = moduleThemes;
        this.saveToLocal();
      }),
    );
  }

  protected patchColours() {
    if (this.moduleThemes)
      for (const item of this.moduleThemes) {
        const map = Config.systemMetadataMap.get(item.system);
        if (map) map.colourCode = item.colourCode || map.colourCode;
      }
  }
}
