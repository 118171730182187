import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '@environments/environment';
import { catchError } from 'rxjs';

export const JupiterEndpointsInterceptor: HttpInterceptorFn = (req, next) => {
  if (
    req.url.startsWith(environment.apiBaseUrl + '/rest/') ||
    req.url.startsWith(environment.apiBaseUrl + '/admin/rest/')
  ) {
    const newUrl = req.url
      .replace(environment.apiBaseUrl + '/rest/users', environment.apiBaseUrl + '/admin/rest/user')
      .replace(environment.apiBaseUrl + '/admin/rest/users', environment.apiBaseUrl + '/admin/rest/user');
    return next(
      req.clone({
        url: newUrl,
      }),
    ).pipe(
      catchError((e) => {
        return next(req);
      }),
    );
  }
  return next(req);
};
