import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '@environments/environment';
import { catchError } from 'rxjs';
import { Config } from '@configs/index.config';

export const coreToJupiterInterceptor: HttpInterceptorFn = (req, next) => {
  // debugger;
  if (
    req.headers.has(Config.jupiterRequestHeaderKey) ||
    req.url.startsWith(environment.jupiterWorkflowAPIURL) ||
    req.url.startsWith(environment.apiBaseUrl + '/admin/rest/')
  ) {
    const newUrl = req.url
      .replace(environment.apiBaseUrl + '/admin/rest/', environment.jupiterAPIURL + '/admin/rest/v2/')
      .replace(environment.jupiterWorkflowAPIURL + '/api/v1/', environment.jupiterWorkflowAPIURL + '/api/v2/')
      .replace('v2/v2/', 'v2/');
    // environment.jupiterAPIKey;
    // debugger;
    return next(
      req.clone({
        url: notV2Routes.some((x) => x.startsWith(newUrl)) ? newUrl.replace('v2/', '') : newUrl,
        headers: req.headers
          .set('apiKey', `${environment.jupiterAPIKey}`)
          .set('companyCode', `${environment.jupiterCompanyCode}`),
      }),
    ).pipe(
      catchError((e) => {
        // debugger
        // if (e.status == 404)
        return next(
          req.clone({
            url: newUrl.replace('v2/', ''),
          }),
        );
        // throw e;
      }),
    );
  }
  // }
  return next(req);
};
const notV2Routes: string[] = [environment.jupiterAPIURL + '/admin/rest/v2/company'];
