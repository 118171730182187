import { Injectable, inject } from '@angular/core';
import {
  catchError,
  debounceTime,
  filter,
  first,
  forkJoin,
  map,
  mergeMap,
  of,
  retry,
  switchMap,
  tap,
} from 'rxjs';
import { createEffect, ofType } from '@ngrx/effects';

import { BaseEffect } from '../base.effect';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { UserMenuStore } from './usermenu.reducer';
import { environment } from '@environments/environment';
import { StorageService } from '@Services/storage.service';
import { emptyProps } from '@ngrx/store';
import { AuthStore } from '../auth/auth.reducer';
import { UsermenuService } from '@User/usermenu/usermenu-extras/usermenu.service';

@Injectable()
export class UserMenuEffect extends BaseEffect {
  protected service = inject(UsermenuService);
  protected sS = inject(StorageService);

  getFromLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.fetchUserStateDetails),
      filter((x) => !!x.userProfile),
      switchMap((action) => this.service.getUserMenuFromLocal()),
      map((r) =>
        r
          ? UserMenuStore.actions.getUserMenuFromLocalSuccess({ userMenu: r })
          : UserMenuStore.actions.getFromOnline({}),
      ),
    ),
  );

  setMapsFromLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.fetchUserStateDetails),
      switchMap((action) => this.service.getMenuItemsIndexFromLocal()),
      filter((x) => !!x),
      // tap((action) => {
      //   action
      //   debugger;
      // }),
      map((r) =>
        UserMenuStore.actions.setUserMenuMaps({ userMenuConfigMap: r[0], userMenuConfigSimpleIDMap: r[1] }),
      ),
    ),
  );

  setFromLocalSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(UserMenuStore.actions.getUserMenuFromLocalSuccess),
      map((r) => UserMenuStore.actions.setUserMenu(r)),
    ),
  );

  checkForChanges = createEffect(() =>
    this.actions$.pipe(
      ofType(UserMenuStore.actions.getUserMenuFromLocalSuccess),
      debounceTime(500),
      filter((action) => !!action.userMenu),
      switchMap((action) =>
        this.service.getUserMenuByCodeLite(action.userMenu.userMenuConfig.userMenu).pipe(
          retry(3),
          map((lite) => ({ lite, userMenu: action.userMenu })),
        ),
      ),
      filter(({ lite, userMenu }) => lite?.updatedOn != userMenu?.userMenuConfig?.updatedOn),
      tap((e) => {
        // debugger;
      }),
      map((r) => UserMenuStore.actions.getFromOnline({ usermenuCode: r.userMenu.userMenuConfig.userMenu })),
    ),
  );

  getFromOnline = createEffect(() =>
    this.actions$.pipe(
      ofType(UserMenuStore.actions.getFromOnline),
      switchMap((action) =>
        action.usermenuCode
          ? of(action.usermenuCode)
          : this.store.select(AuthStore.selectors.userProfile).pipe(
              first((x) => !!x),
              map((x) => x.users.userMenu),
            ),
      ),
      filter((usermenuCode) => !!usermenuCode),
      switchMap((usermenuCode) => this.service.getUserMenuByCode(usermenuCode)),
      map((r) => UserMenuStore.actions.getFromOnlineSuccess({ userMenu: r })),
    ),
  );

  setFromOnlineSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(UserMenuStore.actions.getFromOnlineSuccess),
      map((r) => UserMenuStore.actions.setUserMenu(r)),
    ),
  );

  generateUserMenuMapsFromOnline = createEffect(() =>
    this.actions$.pipe(
      ofType(UserMenuStore.actions.getFromOnlineSuccess),
      map((r) => UserMenuStore.actions.generateUserMenuMaps(r)),
    ),
  );

  saveToLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(UserMenuStore.actions.getFromOnlineSuccess),
      switchMap((action) => this.service.saveUserMenuToLocal(action.userMenu)),
      map((r) => UserMenuStore.actions.saveToLocalSuccess()),
    ),
  );

  generateUserMenuMaps = createEffect(() =>
    this.actions$.pipe(
      ofType(UserMenuStore.actions.generateUserMenuMaps),
      switchMap((action) =>
        forkJoin([
          of(this.service.backendMenuConfigsToMap2(action.userMenu.userMenuConfigDetails)),
          of(this.service.generateSimpleIDMap(action.userMenu.userMenuConfigDetails)),
        ]),
      ),
      map((r) =>
        UserMenuStore.actions.generateUserMenuMapsSuccess({
          userMenuConfigMap: r[0],
          userMenuConfigSimpleIDMap: r[1],
        }),
      ),
    ),
  );

  setGeneratedMaps = createEffect(() =>
    this.actions$.pipe(
      ofType(UserMenuStore.actions.generateUserMenuMapsSuccess),
      map((r) => UserMenuStore.actions.setUserMenuMaps(r)),
    ),
  );
  saveUserMenuMapsToLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(UserMenuStore.actions.generateUserMenuMapsSuccess),
      switchMap((action) =>
        this.service.saveMenuItemsIndexToLocal(action.userMenuConfigMap, action.userMenuConfigSimpleIDMap),
      ),
      map((r) => UserMenuStore.actions.end()),
    ),
  );
}
