import { Component } from '@angular/core';
import { SharedModule } from '../../../../shared.module';

@Component({
  selector: 'user-help-notes',
  imports: [SharedModule],
  templateUrl: './user-help-notes.component.html',
  styleUrl: './user-help-notes.component.scss',
})
export class UserHelpNotesComponent { }
