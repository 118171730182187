import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardFaqAdminComponent } from './FAQ/dashboard-faq-admin/dashboard-faq-admin.component';
import { DashboardAdminComponent } from '@Layout/dashboard-admin/dashboard-admin.component';
import { FormLayoutComponent } from '@Layout/form-layout/form-layout.component';
import { DashboardFaqCreateNewComponent } from './FAQ/dashboard-faq-create-new/dashboard-faq-create-new.component';
import { DashboardFaqViewComponent } from './FAQ/dashboard-faq-view/dashboard-faq-view.component';
import { ClientViewApiComponent } from './API/pages/client-view-api/client-view-api.component';
import { DashboardClientAdminComponent } from '@Layout/dashboard-client-admin/dashboard-client-admin.component';
import { EVFunctions } from '@configs/base-functions';
import { authRoutes } from './auth/auth.routes';
import { authGuard } from './auth/auth.guard';
export const routes: Routes = [
  { path: 'login', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'help',
    data: { title: 'Jupiter Help' },
    loadComponent: () => import('./admin-help/admin-help.component'),
  },
  { path: 'auth', children: authRoutes },
  {
    path: '',
    canActivateChild: [authGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'dashboard/faq/view', component: DashboardFaqViewComponent },
      { path: 'dashboard/faq/admin', component: DashboardFaqAdminComponent },
      { path: 'dashboard/faq/admin/create', component: DashboardFaqCreateNewComponent },
      {
        path: 'cms',
        loadChildren: () =>
          import('projects/evolutics-shared-lib/src/lib/Reusables/reusable-pages/cms/cms.routes'),
      },
      {
        path: '',
        component: FormLayoutComponent,
        children: [
          { path: 'api', loadChildren: () => import('./API/API.module').then((m) => m.APIModule) },
          { path: 'api/show/client', component: ClientViewApiComponent },
          {
            path: 'setup',
            title: 'Setup',
            loadChildren: () => import('./Setup/setup.module').then((m) => m.SetupModule),
          },
          { path: 'faq', loadChildren: () => import('./FAQ/FAQ.module').then((m) => m.FAQModule) },
          { path: 'db', loadChildren: () => import('./DB/DB.module').then((m) => m.DBModule) },
          {
            path: 'analytics',
            loadChildren: () => import('./Analytics/Analytics.module').then((m) => m.AnalyticsModule),
          },
          { path: 'task', loadChildren: () => import('./Tasks/Tasks.module').then((m) => m.TasksModule) },
          {
            path: 'admin',
            children: [
              {
                path: 'user',
                loadChildren: () =>
                  import('./Admin/user/user-pages/create-user/create-user.module').then(
                    (m) => m.CreateUserModule,
                  ),
              },
              {
                path: 'userGroup',
                children: EVFunctions.extendRoute(
                  {
                    path: 'group',
                    loadChildren: () =>
                      import('./Admin/adminusergroup/adminusergroup.module').then(
                        (m) => m.AdminusergroupModule,
                      ),
                    data: { title: 'Admin / User Group' },
                  },
                  null,
                  () =>
                    import('./Admin/adminusergroup/adminusergroup.module').then(
                      (m) => m.AdminusergroupModule,
                    ),
                ),
              },
              {
                path: 'company',
                loadChildren: () => import('./company/company.module').then((m) => m.CompanyModule),
              },
              {
                path: 'menus',
                loadChildren: () => import('./Admin/usermenu/usermenu.module').then((m) => m.UsermenuModule),
              },
              {
                path: 'release',
                loadChildren: () => import('./Admin/release/release.module').then((m) => m.ReleaseModule),
              },
              {
                path: 'suggestion',
                loadChildren: () =>
                  import('./Admin/suggestion/suggestion.module').then((m) => m.SuggestionModule),
              },
              {
                path: 'project',
                loadChildren: () => import('./project/project.module').then((m) => m.ProjectModule),
              },
            ],
          },
          { path: 'company', redirectTo: 'admin/company' },
          { path: 'suggestion', redirectTo: 'admin/suggestion' },
          { path: 'project', redirectTo: 'admin/project' },
          {
            path: 'application',
            loadChildren: () => import('./application/application.module').then((m) => m.ApplicationModule),
          },
          {
            path: 'reports',
            loadChildren: () =>
              import('@Reusables/reusable-pages/Report/report.module').then((m) => m.ReportModule),
          },
          {
            path: 'home',
            component: DashboardAdminComponent,
            loadChildren: () => import('./Home/home.module').then((m) => m.HomeModule),
          },
          {
            path: 'profile',
            children: [
              {
                path: '',
                data: { title: 'Profile' },
                loadComponent: () => import('./profile/profile.component').then((c) => c.ProfileComponent),
              },
            ],
          },
          {
            path: 'suggestions',
            loadChildren: () =>
              import('./Admin/suggestion/suggestion.module').then((m) => m.SuggestionModule),
          },
        ],
      },
      {
        path: 'client',
        loadChildren: () => import('./client-view/client-view.module').then((m) => m.ClientViewModule),
      },
      {
        path: 'client-libraries',
        loadChildren: () =>
          import('./client-libraries/client-libraries.module').then((m) => m.ClientLibrariesModule),
      },
      {
        path: 'client-home',
        component: DashboardClientAdminComponent,
        loadChildren: () => import('./Client-Home/client-home.module').then((m) => m.ClientHomeModule),
      },
      {
        path: '',
        loadChildren: () =>
          import('@Reusables/reusable-pages/extra-pages/extra-pages.module').then((m) => m.ExtraPagesModule),
      },
    ],
  },
];
@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule {}
