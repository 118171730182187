import { ESystem, ESystemBusLine } from '@Shared/models/index.model';

export interface IModuleTheme {
  system: ESystem;
  busLine: ESystemBusLine;
  colourCode: string;
  id: string;
  // userCode: string;
  // userName: string;
  refCat: EModuleThemeCat;
  refNo: string;
}

export enum EModuleThemeCat {
  app = 'APP',
}
