import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'; 
import { LoggerInterceptorService as LS } from 'ets-fe-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class LoggerInterceptorService extends LS implements HttpInterceptor {
  
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
  // debugger
    return super.intercept(req,next)
  }
}


// export { LoggerInterceptorService } from 'ets-fe-ng-sdk';
