import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdlerComponent } from './idler.component';
import { IdlerService } from './idler.service';

@NgModule({
  imports: [CommonModule, IdlerComponent],
  providers: IdlerModule.providers,
})
export class IdlerModule {
  static providers = [IdlerService];
}
