import { Component, computed, effect, inject, linkedSignal, signal, ViewEncapsulation } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { rxResource } from '@angular/core/rxjs-interop';
import { UserHelperService } from '../user-helper.service';
import { MatListModule } from '@angular/material/list';
import { mergeMap, timer } from 'rxjs';
import { UserHelperModuleComponent } from '../user-helper-module.component';
import {
  ICMSContent,
  ICMSReference,
  ICMSResource,
  ICMSResourceQuery,
} from '@Reusables/reusable-pages/cms/cms.model';
import ViewCMSResourceComponent from '../../../../../Reusables/reusable-pages/cms/cms-resource/view-cms-resource/view-cms-resource.component';
import { CMSResourceComponent } from '../../../../../Reusables/reusable-pages/cms/cms-resource/cms-resource/cms-resource.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogConfig } from '@angular/material/dialog';
import { CdkDrag } from '@angular/cdk/drag-drop';

@Component({
  selector: 'user-help-help',
  imports: [SharedModule, MatListModule, ViewCMSResourceComponent, MatButtonModule],
  templateUrl: './user-help-help.component.html',
  styleUrl: './user-help-help.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class UserHelpHelpComponent extends UserHelperModuleComponent {
  protected readonly listResource = rxResource({
    request: () => {
      this.currentRoute();
      return {
        searchHelpFunction: this.service.config().searchHelpFunction || this.service.searchHelp,
        path: location.pathname,
      };
    },
    loader: ({ request }) =>
      timer(500).pipe(mergeMap(() => request.searchHelpFunction({ path: request.path }))),
  });
  readonly loading = computed(() => this.listResource.isLoading());
  readonly list = computed(() =>
    this.listResource.value()?.map((x) => ({ ...x, query: { refId: x.reference.refId } })),
  );
  readonly hasList = computed(() => this.listResource.hasValue());
  readonly content = signal<ICMSContent>(null);
  readonly contentQuery = linkedSignal<ICMSResourceQuery>(() =>
    this.content() ? { helpGuideContentId: this.content()?.id } : null,
  );

  readonly helpMargin = signal('10px');
  readonly baseconfig = computed<MatDialogConfig>(() => ({
    position: { right: this.helpMargin(), bottom: this.helpMargin() },
    panelClass: 'user-help-modal',
  }));
  readonly ESizeState = ESizeState;
  readonly sizeState = signal(ESizeState.third);
  readonly sizes = computed(() => {
    const arr = Object.values(ESizeState),
      length = arr.length;

    return arr.map((x, index) => ({
      code: x,
      title: x.split('-').join(' '),
      width: index == length - 1 ? 100 : (index / length) * 100,
    }));
  });

  private readonly sizeSateEffect = effect(() => {
    const sizeState = this.sizeState();
    const doc = document.querySelector<HTMLDivElement>('.user-help-modal');
    if (doc) {
      let width = 'auto';
      if (sizeState == ESizeState.full) width = `calc(100% - ( 2 * ${this.helpMargin()}))`;
      if (sizeState == ESizeState.half) width = '50%';
      if (sizeState == ESizeState.third) width = '30%';
      // else width = '20%';
      doc.style.width = width;
    }
  });

  refresh() {
    this.listResource.reload();
  }

  chooseItem(content: ICMSContent, reference: ICMSReference) {
    this.content.set(content);
    this.contentQuery.set(reference);
    this.service.toggle();
    this.sizeState.set(ESizeState.third);
  }
}

enum ESizeState {
  minimized = 'minimized',
  third = 'third-view',
  half = 'half-view',
  full = 'full-view',
}
