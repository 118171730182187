import { Component, computed, inject } from '@angular/core';
import { UserHelperService } from './user-helper.service';
import { ActivatedRoute } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { AppRouteService } from 'ets-fe-ng-sdk';

@Component({
  imports: [],
  template: ' ',
})
export class UserHelperModuleComponent {
  readonly service = inject(UserHelperService);
  readonly routeService = inject(AppRouteService);
  readonly route = inject(ActivatedRoute);

  readonly currentRoute = computed(() => this.routeService.currentRouteSignal());
}
