import { isDevMode } from '@angular/core';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { IAppState } from './index.state';
import { CodeStore } from './code/code.reducer';
import { AuthStore } from './auth/auth.reducer';
import { LabelsStore } from './labels/labels.reducer';
import { UserMenuStore } from './usermenu/usermenu.reducer';
import { TranslationGlossaryStore } from './translation/translation.reducer';
import { UserStateStore } from './user-state/user-state.reducer';
import { TablesStore } from './tables/tables.reducer';

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    // console.log(action.type, { action, state });
    return reducer(state, action);
  };
}
export const metaReducers: MetaReducer[] = isDevMode() ? [debug] : [];

export const reducers: ActionReducerMap<IAppState> = {
  // code: CodeStore.reducer,
  auth: AuthStore.reducer,
  tables: TablesStore.reducer,
  labels: LabelsStore.reducer,
  userMenu: UserMenuStore.reducer,
  userState: UserStateStore.reducer,
  translationGlossary: TranslationGlossaryStore.reducer,
};
