import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { IGetQuery } from '@Shared/models/index.model';

export interface IUADeletionPayload {
  id: number;
  selected: boolean;
}
export type IUASearchObj = Partial<
  IGetQuery<{
    loggedTimeFrom: string;
    loggedTimeTo: string;
    pageNumber: number;
    pageSize: number;
    pageTitle: string;
    screen: string;
    sortBy: string;
    userAgent: string;
    userName: string;
  }>
>;
export interface IUASearchResponse {
  id: number;
  userName?: string;
  loggedIn: boolean;
  ipAddress: string;
  loggedTime: string;
  userAgent: string;
  pageUrl?: string;
  screen?: string;
  pageTitle: string;
  activityDescription?: string;
  requestUrl?: string;
  requestMethod?: string;
  companyCode?: string;
}
export interface IUserActivity {
  id?: number;
  activityDescription?: string;
  pageTitle?: string;
  pageUrl?: string;
  requestMethod?: string;
  requestUrl?: string;
  code: EUA;
}
export class UserActivity implements IUserActivity {
  id: number;
  activityDescription: string;
  pageTitle: string;
  pageUrl: string;
  requestMethod: string;
  requestUrl: string;
  code: EUA;
  constructor(data: IUserActivity) {
    Object.assign(this, data);
    this.activityDescription = (
      codeToDescriptionMap.find((x) => x.key == this.code)?.value +
      ' ' +
      (this.activityDescription ? `{${this.activityDescription}}` : '')
    )?.trim();
    this.pageUrl = this.pageUrl || location.href;
    // debugger;
    this.pageTitle = this.pageTitle || environment.pageTitle;
  }
}
export enum EUA {
  exported = 'export',
  visited = 'visited',
  searched = 'searched',
  downloaded = 'downloaded',
  clicked = 'clicked',
}
const codeToDescriptionMap = [
  { key: EUA.exported, value: 'Exported data' },
  { key: EUA.visited, value: 'User visited new page' },
  { key: EUA.searched, value: 'User performed a search' },
  { key: EUA.downloaded, value: 'User downloaded an item' },
  { key: EUA.clicked, value: 'User clicked on a button' },
];
